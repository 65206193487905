import imgManutencao from "../../assets/img/slide/manutencao.png";
import iconeManutencao from "../../assets/img/slide/manutencao.svg";
import imgLogoMobile from "../../assets/img/logo-mobile.svg";
import imgRetorno from "../../assets/img/slide/retorno.svg";

import imgLogo from "../../assets/img/nova-logo.svg";
import { MaintenanceContainer } from "./styles";
// import { formatDateToString } from "../../util/formatDateToString";

export function Maintenance() {
  // const today = formatDateToString(new Date().toISOString());

  // function getReturnDay() {
  //   const xmasDays = ["24/12/2024", "25/12/2024"];
  //   const newYearDays = ["31/12/2024", "01/01/2025", "02/01/2025"];

  //   if (xmasDays.includes(today)) return "26/12/2024";
  //   if (newYearDays.includes(today)) return "02/01/2025";
  // }

  const returnDate = "05/03/2025 - 12:00"

  return (
    <MaintenanceContainer>
      <div className="container-img">
        <img src={imgLogoMobile} alt="" className="logo-mobile" />
        <div className="container-img-manutencao">
          <img src={imgManutencao} width="500" height="600" alt="" />
        </div>
      </div>

      <div className="content-form manutencao">
        <img src={iconeManutencao} className="icon" alt="" />
        <h1>FIQUE ATENTO!</h1>

        <p className="text-aviso">
          A Plataforma <strong>YETZPAY</strong> está passando por uma{" "}
          <strong>ATUALIZAÇÃO</strong> <br />
          para oferecer ainda mais recursos.
          <br />
          <br />
          <strong className="advise">
            Durante a atualização seu cartão também pode ficar
            indisponível.
          </strong>
        </p>

        <h3>Já Já voltamos :)</h3>

        <div className="retorno">
          <img src={imgRetorno} alt="" />
          <div className="container-text">
            <p>Previsão de retorno:</p>
            <span>{returnDate}</span>
          </div>
        </div>

        <img src={imgLogo} alt="" className="logo" />
      </div>
    </MaintenanceContainer>
  );
}

import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ptBR } from "@mui/material/locale";

import { GlobalStyle } from "./styles/global";
import { SwitchRoutes } from "./components/SwitchRoutes";
import Interceptor from "./services/interceptor";
import "./styles/fonts.css";
import "../node_modules/swiper/swiper.min.css";
import "../node_modules/pretty-checkbox/src/pretty-checkbox.scss";
import { Clarity } from "./components/Clarity";
import { Maintenance } from "./components/Maintenance";
import { formatDateToString } from "./util/formatDateToString";

export function App() {
    const theme = createTheme(
        {
            palette: {
                primary: { main: "#5DB356" },
            },
        },
        ptBR
    );

    const today = formatDateToString(new Date().toISOString());
    const currentTime = new Date();
    const maintenanceDays = ["01/03/2025", "02/03/2025", "03/03/2025", "04/03/2025", "05/03/2025"];

    const isDayAfter8AM = currentTime.getHours() >= 12;
    const isMaintenanceDay = maintenanceDays.includes(today) && !(today === "05/03/2025" && isDayAfter8AM);

    if (isMaintenanceDay) {
        return (
            <Maintenance />
        );
    }

    return (
        <>
            {process.env.NODE_ENV === 'production' && <Clarity />}
            <ThemeProvider theme={theme}>
                <GlobalStyle />
                <Interceptor />
                <BrowserRouter>
                    <SwitchRoutes />
                    <ToastContainer />
                </BrowserRouter>
            </ThemeProvider>
        </>
    );
}

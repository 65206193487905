import { EmailIcon } from "../../assets/img/EmailIcon";
import { InterrogationIcon } from "../../assets/img/InterrogationIcon";
import { MessageIcon } from "../../assets/img/MessageIcon";
import { SmsIcon } from "../../assets/img/SmsIcon";
import { WhatsAppIcon } from "../../assets/img/WhatsappIcon";
import message from "../../assets/img/message.svg";
import setaDirVerdefrom from "../../assets/img/seta-dir-verde.svg";
import pdf from "../../assets/pdf/tutorial_desbloqueio_SMS_2024_pay.pdf";
import Collapse from "../Collapse";
import Channel from "./Channel";
import { NeedHelp, ResendCodeContainer, ResendFields } from "./styles";

interface IResendCode {
    onResend: (channel_name: string) => void;
    sentTo?: "SMS" | "WhatsApp" | "E-mail";
    user: {
        mobile_number?: string;
        email?: string;
    };
    useEmail?: boolean;
    useSMS?: boolean;
    useWhatsApp?: boolean;
    theme?: "primary" | "secondary";
}

export const ResendCode = ({
    user: { mobile_number, email },
    theme = "primary",
    sentTo = "WhatsApp",
    useEmail,
    useSMS,
    useWhatsApp,
    onResend,
}: IResendCode) => {
    function handleResendCode(channelName: string) {
        if (onResend) onResend(channelName);
    }

    return (
        <ResendCodeContainer>
            <Collapse
                title={"Clique aqui"}
                description={`(Se não receber seu código via SMS)`}
                icon={<InterrogationIcon />}
            >
                <ResendFields $theme={theme}>
                    <a
                        href={pdf}
                        target="_blank"
                        className="container-text"
                        rel="noreferrer"
                    >
                        <div className="tutorial">
                            <div>
                                <img src={message} alt="" />
                                <span>
                                    <p>Entenda como liberar o bloqueio</p>
                                    <p>
                                        <strong>DO SMS NO SEU CELULAR</strong>
                                    </p>
                                </span>
                            </div>
                            <img src={setaDirVerdefrom} alt="" />
                        </div>
                    </a>
                    {mobile_number ? (
                        <>
                            {useWhatsApp && (
                                <Channel
                                    icon={<WhatsAppIcon />}
                                    text="Receber por WhatsApp"
                                    value={mobile_number}
                                    onSend={() => handleResendCode("whatsapp")}
                                />
                            )}

                            {useSMS && (
                                <Channel
                                    icon={<SmsIcon />}
                                    text="Receber por SMS"
                                    value={mobile_number}
                                    onSend={() => handleResendCode("sms")}
                                />
                            )}
                        </>
                    ) : null}

                    {email && useEmail ? (
                        <Channel
                            icon={<EmailIcon />}
                            text="Receber por E-mail"
                            value={email}
                            onSend={() => handleResendCode("email")}
                        />
                    ) : null}

                    <fieldset />

                    <NeedHelp href="/faq">
                        <MessageIcon />
                        <span>PRECISA DE AJUDA ?</span>
                    </NeedHelp>
                </ResendFields>
            </Collapse>
        </ResendCodeContainer>
    );
};
